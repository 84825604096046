import React, { useState } from "react";
import i18n, {t} from "i18next";
import { Trans } from 'react-i18next';
import imagePayment from "../assets/payment.jpg";

export default function PaymentSection() {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const consent = <span><Trans
        i18nKey="consent_payment"
        components={{
            1: <a href={i18n.language === 'es' ? "/policyES/" : "/policyEN/"} className="text-blue-500 underline hover:text-blue-700 hover:underline" target="_blank"></a>
            }}
    /></span>

    return (
        <section className="relative md:py-24 py-16 bg-primary-light dark:bg-primary-dark" id='payment'>
            <div className="container mx-auto">
                <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                    <div className="lg:col-span-5">
                        <img src={imagePayment} className="rounded-lg shadow-lg" alt="" />
                    </div>
                    <div className="lg:col-span-7 flex flex-col">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium text-primary-light dark:text-primary-dark">
                            {t('payment_title')}
                        </h3>
                        <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">
                            {t('payment_message')}
                        </p>
                        <div className="w-full border-t border-slate-300 my-6"></div>
                        <div className="flex items-center mb-4">
                            <input
                            type="checkbox"
                            id="checkbox-payment"
                            name="consent"
                            className="form-checkbox h-5 w-5 text-blue-700 rounded focus:ring-2 focus:ring-blue-600"
                            onChange={handleCheckboxChange}
                            required></input>
                            <label for="checkbox-payment" className="ml-2 text-m font-medium text-primary-light dark:text-primary-dark">{consent}</label>
                        </div>
                        <button
                            onClick={() => window.location.href = "https://buy.stripe.com/6oE5og0p0aRh9PiaEG"}
                            disabled={!isChecked}
                            className={`btn ${isChecked ? 'bg-button-light dark:bg-button-dark text-buttonText-light dark:text-buttonText-dark hover:bg-blue-600 border-blue-900 hover:border-blue-900' : 'bg-gray-500' } rounded-md h-11 justify-center flex items-center w-2/5`}>
                            {t('buy_consultation')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
