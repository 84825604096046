import React from "react";
import "../i18n";
import {t} from "i18next";
import "../index.css";
import {ContactUsForm} from "../components/ContactUsForm";

export default function ContactSection() {
    return (
        <>

            <section className="relative md:py-24 py-16 bg-secondary-light dark:bg-secondary-dark" id="contact">
                <div className="container lg mx-auto">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium text-primary-light dark:text-primary-dark">{t('contact_us')}</h3>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
                        <ContactUsForm/>
                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex mt-4">
                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg text-primary-light dark:text-primary-dark font-medium">{t('contact_email')}</h5>
                                        <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">info@livespainvisa.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 mt-8 items-center gap-6">
                        <div className="flex flex-col w-full">
                            <div className="text-center lg:text-left">
                                <h3 className="md:text-2xl text-xl text-primary-light dark:text-primary-dark font-semibold">Barcelona</h3>
                                <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto mb-4">Calle Comte D’Urgell 100, piso 7-3<br/>
                                    08011 Barcelona - España</p>
                            </div>
                            <div className="text-center lg:text-left">
                                <h5 className="text-lg text-primary-light dark:text-primary-dark font-medium">Tel/WhatsApp</h5>
                                <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">
                                    +34659114553 <br/>
                                    +34669017348<br/>&nbsp;</p>
                            </div>
                            <iframe className="lg:py-8 w-full"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2993.5596190273295!2d2.154513874814542!3d41.38365167130031!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12a4a2885603ca19%3A0xc18a4e40dc2465ee!2sCarrer%20del%20Comte%20d&#39;Urgell%2C%20100%2C%20L&#39;Eixample%2C%2008015%20Barcelona!5e0!3m2!1sen!2ses!4v1704308617479!5m2!1sen!2ses"
                                    width="100%" height="450" allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="flex flex-col w-full">
                            <div className="text-center lg:text-left">
                                    <h3 className="md:text-2xl text-xl text-primary-light dark:text-primary-dark font-semibold">Madrid</h3>
                                    <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto mb-4">Calle General Lacy 21, 1 Derecha<br/>
                                        28045 Madrid - España</p>
                                </div>
                            <div className="text-center lg:text-left">
                                    <h5 className="text-lg text-primary-light dark:text-primary-dark font-medium">Tel/WhatsApp</h5>
                                    <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">
                                        +34676035990<br/>&nbsp;<br/>&nbsp;</p>
                                </div>
                            <iframe className="lg:py-8 w-full"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3038.31499952923!2d-3.6931482246106286!3d40.401872056544576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd42263addfca19f%3A0x3a0340c7cd909c15!2sCalle%20del%20Gral.%20Lacy%2C%2021%2C%20Arganzuela%2C%2028045%20Madrid%2C%20Spain!5e0!3m2!1sen!2sus!4v1710694849357!5m2!1sen!2sus"
                                    width="100%" height="450" allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
