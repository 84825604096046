import React, { useState, useEffect } from "react";
import {Route, Routes} from "react-router-dom"
import Index from "./pages/index";
import PolicyEN from "./pages/policyEN";
import PolicyES from "./pages/policyES";

export default function App() {

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        document.documentElement.setAttribute('dir', 'ltr');
        handleRouteChange();
    }, []);


    // Route change method
    const handleRouteChange = () => {
        setLoading(false);
    }

    return (
        <>
            {/*{loading && <Loader />}*/}
            <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/policyEN/" element={<PolicyEN />} />
                <Route path="/policyES/" element={<PolicyES />} />
                {/*<Route path="/news/" element={<NewsPage />} />*/}
                {/*<Route path="/team/" element={<TeamPage />} />*/}
                {/*<Route path="/contact/" element={<ContactPage />} />*/}
            </Routes>
        </>
    )
}
