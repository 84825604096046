import React from "react";
import "../index.css";

export default function PolicyPageEN() {
    return (
        <>
<section className="relative md:py-24 py-16 bg-slate-900">
    <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-7">
                <div className="lg:ms-7">
                    <h2 className="text-white text-2xl mb-4">Política de Privacidad</h2>

                    <h3 className="text-white text-xl mt-6 mb-2">1. Introducción</h3>
                    <p className="text-slate-300 mb-4">Live Spain Visa Consulting ("nosotros", "nuestro") está comprometido con la protección de su privacidad. Esta Política de Privacidad explica cómo recopilamos, usamos y compartimos información sobre usted cuando visita nuestro sitio web https://livespainvisa.com/ (el "Sitio") y utiliza nuestros servicios.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">2. Información que Recopilamos</h3>
                    <ul className="list-disc list-inside text-slate-300 mb-4">
                        <li><strong>Información Personal:</strong> Cuando completa un formulario en nuestro Sitio, recopilamos información personal como su nombre, dirección de correo electrónico y el contenido de su mensaje.</li>
                        <li><strong>Información de Pago:</strong> Cuando realiza una compra a través de nuestro Sitio, su información de pago es recopilada y procesada por nuestro procesador de pagos, Stripe. No almacenamos su información de pago en nuestros servidores.</li>
                    </ul>

                    <h3 className="text-white text-xl mt-6 mb-2">3. Cómo Usamos su Información</h3>
                    <ul className="list-disc list-inside text-slate-300 mb-4">
                        <li>Para proporcionar, mantener y mejorar nuestros servicios.</li>
                        <li>Para comunicarnos con usted, responder a sus consultas y brindar atención al cliente.</li>
                        <li>Para procesar transacciones y enviarle información relacionada, incluidos confirmaciones de compra y facturas.</li>
                        <li>Para enviarle comunicaciones de marketing, si ha optado por recibirlas.</li>
                    </ul>

                    <h3 className="text-white text-xl mt-6 mb-2">4. Compartir su Información</h3>
                    <ul className="list-disc list-inside text-slate-300 mb-4">
                        <li><strong>Con Proveedores de Servicios:</strong> Compartimos su información con proveedores de servicios externos que realizan servicios en nuestro nombre, como EmailJS para envíos de formularios y Stripe para el procesamiento de pagos.</li>
                        <li><strong>Por Razones Legales:</strong> Podemos compartir su información para cumplir con obligaciones legales o responder a solicitudes legales de autoridades públicas.</li>
                    </ul>

                    <h3 className="text-white text-xl mt-6 mb-2">5. Retención de Datos</h3>
                    <p className="text-slate-300 mb-4">Retenemos su información personal durante el tiempo necesario para proporcionar nuestros servicios, cumplir con obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">6. Sus Derechos</h3>
                    <p className="text-slate-300 mb-4">Tiene derecho a acceder, corregir o eliminar su información personal. También puede tener derecho a oponerse o restringir ciertos tipos de procesamiento de datos y a solicitar la portabilidad de datos. Para ejercer estos derechos, comuníquese con nosotros en info@livespainvisa.com.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">7. Seguridad de los Datos</h3>
                    <p className="text-slate-300 mb-4">Implementamos medidas técnicas y organizativas apropiadas para proteger su información personal contra el acceso, uso o divulgación no autorizados.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">8. Transferencias Internacionales de Datos</h3>
                    <p className="text-slate-300 mb-4">Si se encuentra fuera de la Unión Europea, su información puede ser transferida y procesada en países fuera de su propia. Nos aseguramos de que se implementen las salvaguardas adecuadas para proteger sus datos.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">9. Cambios a Esta Política de Privacidad</h3>
                    <p className="text-slate-300 mb-4">Podemos actualizar esta Política de Privacidad de vez en cuando. Le notificaremos sobre cualquier cambio publicando la nueva Política de Privacidad en nuestro Sitio. Se le aconseja revisar esta Política de Privacidad periódicamente para cualquier cambio.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">10. Contáctenos</h3>
                    <p className="text-slate-300 mb-4">Si tiene alguna pregunta sobre esta Política de Privacidad, comuníquese con nosotros en:</p>
                    <p className="text-slate-300 mb-4">Live Spain Visa Consulting<br />info@livespainvisa.com<br />Calle Comte D’Urgell 100, piso 7-3 08011 Barcelona - España <br />Calle General Lacy 21, 1 Derecha 28045 Madrid - España</p>
                </div>
            </div>
        </div>
    </div>
</section>

        </>
    );
}
