import React from "react";
import "../index.css";
import {t} from "i18next";
import imageTeam from "../assets/team.jpg";
export default function TeamSection() {
    return (
        <>
            <section className="relative md:py-24 py-16 bg-primary-light dark:bg-primary-dark" id="team">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <img src={imageTeam} className="rounded-lg shadow-lg relative" alt=""/>
                            </div>
                        </div>

                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className="mb-4 md:text-2xl text-xl font-medium text-primary-light dark:text-primary-dark">{t('team')}</h3>
                                <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">{t('team_content')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
