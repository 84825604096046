import React from 'react';
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import logoImage from "../assets/LIVESPAIN-logo.png";
import logoImageDark from "../assets/LIVESPAIN-logo-dark.png";
import "../index.css";
import ThemeSwitcher from '../components/ThemeSwitcher';

const Header = ({ isDarkMode, toggleDarkMode }) => {
    const { i18n, t } = useTranslation();

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang).then(() => window.location.reload());
    };

    return (
        <Disclosure as="nav" className="navbar fixed top-0 inset-x-0 z-50">
            {({ open }) => (
                <>
                    <div className={`overlay ${isDarkMode ? 'overlay-dark' : 'overlay-light'}`}></div>
                    <div className="header-container flex-wrap relative z-10">
                        <div className="flex-1 flex items-center sm:justify-between">
                            <div className="navbar-brand flex-shrink-0">
                                <img className="logo" src={isDarkMode ? logoImage : logoImageDark} alt="Logo" />
                            </div>
                            <div className="hidden sm:flex sm:items-center sm:space-x-4">
                                <ul className={"navbar-nav nav-items font-semibold text-primary-light dark:text-primary-dark"}>
                                    <Link className="nav-item hover:text-blue-600 py-2 rounded-md" to="home" spy={true} smooth={true} duration={500}>
                                        {t('home')}
                                    </Link>
                                    <Link className="nav-item hover:text-blue-600 py-2 rounded-md" to="services" spy={true} smooth={true} duration={500}>
                                        {t('services')}
                                    </Link>
                                    <Link className="nav-item hover:text-blue-600 py-2 rounded-md" to="news" spy={true} smooth={true} duration={500}>
                                        {t('news')}
                                    </Link>
                                    <Link className="nav-item hover:text-blue-600 py-2 rounded-md" to="team" spy={true} smooth={true} duration={500}>
                                        {t('team')}
                                    </Link>
                                    <Link className="nav-item hover:text-blue-600 py-2 rounded-md" to="contact" spy={true} smooth={true} duration={500}>
                                        {t('contact')}
                                    </Link>
                                    <Link className="nav-item hover:text-blue-600 py-2 rounded-md" to="payment" spy={true} smooth={true} duration={500}>
                                        {t('payment')}
                                    </Link>
                                </ul>
                                {i18n.language === 'en' ? (
                                    <a className="nav-item text-blue-600 hover:text-blue-700 py-2 rounded-md text-sm font-semibold" onClick={() => changeLanguage('es')}>ES</a>
                                ) : (
                                    <a className="nav-item text-blue-600 hover:text-blue-700 py-2 rounded-md text-sm font-semibold" onClick={() => changeLanguage('en')}>EN</a>
                                )}
                                <ThemeSwitcher toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center">
                            <Disclosure.Button className="sm:hidden inline-flex items-center justify-center p-2 rounded-md text-primary-light dark:text-primary-dark bg-primary-light dark:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                <span className="sr-only">Open main menu</span>
                                {open ? (
                                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                ) : (
                                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                )}
                            </Disclosure.Button>
                            <ThemeSwitcher toggleDarkMode={toggleDarkMode} isDarkMode={isDarkMode} />
                        </div>
                    </div>

                    <Disclosure.Panel className="sm:hidden">
                        <div className="px-2 pt-2 pb-3 space-y-1 text-primary-light dark:text-primary-dark">
                            <Link className="nav-item block px-3 py-2 rounded-md text-base font-medium bg-secondary-light dark:bg-secondary-dark" to="home" spy={true} smooth={true} duration={500}>
                                {t('home')}
                            </Link>
                            <Link className="nav-item block px-3 py-2 rounded-md text-base font-medium bg-secondary-light dark:bg-secondary-dark" to="services" spy={true} smooth={true} duration={500}>
                                {t('services')}
                            </Link>
                            <Link className="nav-item block px-3 py-2 rounded-md text-base font-medium bg-secondary-light dark:bg-secondary-dark" to="news" spy={true} smooth={true} duration={500}>
                                {t('news')}
                            </Link>
                            <Link className="nav-item block px-3 py-2 rounded-md text-base font-medium bg-secondary-light dark:bg-secondary-dark" to="team" spy={true} smooth={true} duration={500}>
                                {t('team')}
                            </Link>
                            <Link className="nav-item block px-3 py-2 rounded-md text-base font-medium bg-secondary-light dark:bg-secondary-dark" to="contact" spy={true} smooth={true} duration={500}>
                                {t('contact')}
                            </Link>
                            <Link className="nav-item block px-3 py-2 rounded-md text-base font-medium bg-secondary-light dark:bg-secondary-dark" to="payment" spy={true} smooth={true} duration={500}>
                                {t('payment')}
                            </Link>
                            {i18n.language === 'en' ? (
                                <a className="nav-item text-blue-800 block px-3 py-2 rounded-md text-base font-semibold" onClick={() => changeLanguage('es')}>ES</a>
                            ) : (
                                <a className="nav-item text-blue-800 block px-3 py-2 rounded-md text-base font-semibold" onClick={() => changeLanguage('en')}>EN</a>
                            )}
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};
export default Header;
