import { useState, useEffect } from 'react';

const RotatingImage = ({ images, interval = 30000 }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, interval);

        return () => clearInterval(intervalId);
    }, []);

    return images[currentImageIndex];
};

export default RotatingImage;
