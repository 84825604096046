import React, {useState, useRef, useEffect} from "react";
import "../index.css";
import {t} from "i18next";
import imageInvest from "../assets/invest.jpg";
import imageReside from "../assets/reside_and_work.jpg";
import imageBusiness from "../assets/start_a_business.jpg";
import imageStudy from "../assets/study.jpg";
import imageRetire from "../assets/retire.jpg";
import imageNationality from "../assets/nationality.jpg";
import { Link } from "react-router-dom";
export default function ServicesSection() {
        const services = [
                {
                        image: imageInvest,
                        title: t('service_invest_title'),
                        context: t('service_invest')
                },
                {
                        image: imageReside,
                        title: t('service_reside_work_title'),
                        context: t('service_reside_work')
                },
                {
                        image: imageBusiness,
                        title: t('service_business_title'),
                        context: t('service_business')
                },
                {
                        image: imageStudy,
                        title: t('service_study_title'),
                        context: t('service_study')
                },
                {
                        image: imageRetire,
                        title: t('service_retire_title'),
                        context: t('service_retire')
                },
                {
                        image: imageNationality,
                        title: t('service_nationality_title'),
                        context: t('service_nationality')
                }
        ]

        const Modal = ({ isOpen, onClose, content }) => {
                const modalContentRef = useRef(null);

                const handleClickOutside = (event) => {
                        console.log("modalContentRef.current " + modalContentRef.current);
                        console.log("event.target " + event.target);
                        console.log("contains " + !modalContentRef.current.contains(event.target));

                        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
                                console.log("closing?");
                                onClose();
                        }
                };

                useEffect(() => {
                        if (isOpen) {
                                document.addEventListener('mousedown', handleClickOutside);
                        }
                        return () => {
                                document.removeEventListener('mousedown', handleClickOutside);
                        };
                }, [isOpen, onClose]);

                if (!isOpen) return null;

                return (
                    <div className="modal-background fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center p-4">
                            <div className="container modal-content bg-primary-light dark:bg-primary-dark rounded-lg shadow-xl overflow-hidden relative py-96" ref={modalContentRef}>
                                    <button onClick={onClose} className="absolute top-0 right-0 text-primary-light dark:text-primary-dark p-2 m-2">
                                            &times;
                                    </button>
                                    <div className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto text-new-line">
                                            {content}
                                    </div>
                            </div>
                    </div>
                );
        };


        const [modalOpen, setModalOpen] = useState(false);
        const [modalContent, setModalContent] = useState(null);

        const openModal = (item) => {
                setModalContent(item.context);
                setModalOpen(true);
        };


        const closeModal = () => {
                setModalOpen(false);
        };

        return (
        <>
                <section className="relative md:py-24 py-16 bg-primary-light dark:bg-primary-dark active" id="services">
                        <div className="container">
                                <div className="grid grid-cols-1 pb-8 text-center">
                                        <h3 className="mb-4 md:text-2xl text-xl font-medium text-primary-light dark:text-primary-dark">{t('services')}</h3>
                                </div>

                                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
                                        {services.map((item, index) => (
                                            <div className="relative rounded-md shadow-sm overflow-hidden group hover:cursor-pointer" onClick={() => openModal(item)} key={index}>
                                                    <img
                                                        src={item.image}
                                                        className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 hover:cursor-pointer transition duration-500 h-max"
                                                        alt="workimage"
                                                    />
                                                    <div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>
                                                    <div className="content">
                                                            <div className="absolute z-10 bottom-4 start-4 transition-all duration-500">
                                                                    <Link to="#" className="h6 text-lg text-primary-dark hover:text-blue-700 transition duration-500 font-semibold bg-black bg-opacity-70 p-1 rounded" onClick={() => openModal(item)}>{item.title}</Link>
                                                            </div>
                                                    </div>
                                            </div>
                                        ))}
                                </div>
                        </div>
                </section>
                <Modal isOpen={modalOpen} onClose={closeModal} content={modalContent} />
 
        </>
    );
}
