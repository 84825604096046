import React from "react";
import "../index.css";
import {t} from "i18next";
import { Trans } from 'react-i18next';
import imageNews from "../assets/latest_news.jpg";
export default function NewsSection() {
    const content = <span><Trans
        i18nKey="news_content"
        components={{
            1: <a href="https://etias.es/%C2%BFqu%C3%A9-es-el-etias" className="text-blue-500 underline hover:text-blue-700 hover:underline">{t('i18nKey')} + </a>,
            2: <a href="https://etias.es/%C2%BFqu%C3%A9-es-el-etias/%C2%BFqui%C3%A9n-necesita-un-permiso-etias" className="text-blue-500 underline hover:text-blue-700 hover:underline">{t('i18nKey')} + </a>,
            3: <a href="https://etias.es/pa%C3%ADses-del-etias/" className="text-blue-500 underline hover:text-blue-700 hover:underline">{t('i18nKey')}</a>
        }}
    /></span>
    return (
        <>
            <section className="relative md:py-24 py-16 bg-secondary-light dark:bg-secondary-dark" id="news">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <img src={imageNews} className="rounded-lg shadow-lg relative" alt=""/>
                            </div>
                        </div>
                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className="mb-4 md:text-2xl text-xl font-medium text-primary-light dark:text-primary-dark">{t('news_title')}</h3>
                                <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">{content}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
