import React from 'react';
import "../index.css";
import logoImage from "../assets/LIVESPAIN-logo.png";
import logoImageDark from "../assets/LIVESPAIN-logo-dark.png";

const Footer = ({ isDarkMode }) => {

    return (
        <footer className="relative py-6 bg-secondary-light dark:bg-secondary-dark">
            <div className="container mx-auto">
                <div className="grid lg:grid-cols-6 items-center">
                    <a>
                        <img className="logo" src={isDarkMode ? logoImage : logoImageDark} alt="Logo" />
                    </a>

                    <div className="md:col-span-5 md:mt-0 mt-8">
                        <div className="text-center">
                            <p className="text-primary-light dark:text-primary-dark">© {(new Date().getFullYear())}{" "} LIVESPAIN. All Rights Reserved.</p>
                            <a className="text-blue-500 underline hover:text-blue-700 hover:underline" href="/policyEN/" target="_blank">Privacy Policy</a>
                            <n className="text-primary-light dark:text-primary-dark"> | </n>
                            <a className="text-blue-500 underline hover:text-blue-700 hover:underline" href="/policyES/" target="_blank">Política de Privacidad</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
