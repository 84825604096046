import React from 'react';
import { useTranslation } from 'react-i18next';
import "../index.css";
import RotatingImage from '../components/RotatingImage';
import headImage1 from "../assets/bull.jpg";
import headImage2 from "../assets/park_de_espana_sevilla.jpg";
import headImage3 from "../assets/city_of_Arts_and_Sciences_Valencia.jpg";
import headImage4 from "../assets/alhambra.jpg";
import headImage5 from "../assets/guggenheim_Museum_Bilbao.jpg";
import headImage6 from "../assets/castle_of_Tossa_de_Mar.jpg";
import headImage7 from "../assets/cuatro_Torres_Business_Area_Madrid.jpg";
import headImage8 from "../assets/park_Guell.jpg";
import headImage9 from "../assets/streat1_Seville.jpg";
import headImage10 from "../assets/la_Pedrera-Casa_Mila.jpg";
import headImage11 from "../assets/metropol_Parasol_Seville.jpg";
import headImage12 from "../assets/streat2_Seville.jpg";
import headImage13 from "../assets/la_Seu_Cathedral_in_Palma_de_Mallorca.jpg";
import imageSagradaFamilia from "../assets/sagrada_familia.jpg";

const HomeSection = ({ isDarkMode }) => {
    const images = [headImage1, headImage2, headImage3, headImage4, headImage5, headImage6, headImage7, headImage8, headImage9, headImage10, headImage11, headImage12, headImage13];
    const { t } = useTranslation();
    
    return (
        <>
            <section
                style={{ backgroundImage: `url(${RotatingImage({ images })})` }}
                className="py-24 lg:py-48 w-full table relative bg-center bg-cover"
                id="home"
            >
                <div className="container relative z-10">
                    <div className="grid grid-cols-1 mt-12">
                        <h4 className={`lg:text-4xl text-3xl text-grey-200 dark:text-primary-dark text-shadow transition lg:leading-normal font-medium mb-7 p-1 rounded gradient-bg ${isDarkMode ? 'gradient-bg-dark' : 'gradient-bg-light'}`}>
                            {t('home_adventure_title')}
                        </h4>
                        <p className={`text-grey-200 dark:text-primary-dark font-semibold max-w-2xl text-lg text-shadow mb-7 p-1 rounded gradient-bg ${isDarkMode ? 'gradient-bg-dark' : 'gradient-bg-light'}`}>
                            {t('home_adventure')}
                        </p>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16 bg-secondary-light dark:bg-secondary-dark">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
                        <div className="lg:col-span-5">
                            <div className="relative">
                                <img src={imageSagradaFamilia} className="rounded-lg shadow-lg relative" alt=""/>
                            </div>
                        </div>

                        <div className="lg:col-span-7">
                            <div className="lg:ms-7">
                                <h3 className="mb-4 md:text-2xl text-xl font-medium text-primary-light dark:text-primary-dark">{t('home_destination_title')}</h3>
                                <p className="text-secondary-light dark:text-secondary-dark max-w-2xl mx-auto">{t('home_destination')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomeSection;
