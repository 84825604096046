import React from 'react';
import { FaSun, FaMoon } from 'react-icons/fa';

const ThemeSwitcher = ({ toggleDarkMode, isDarkMode }) => {
    return (
        <button
            onClick={toggleDarkMode}
            className="relative p-2 rounded-full focus:outline-none transition duration-500 ease-in-out"
            id="theme-toggle"
            aria-label="Toggle Dark Mode"
        >
            <div className={`transform ${isDarkMode ? 'rotate-0' : 'rotate-180'} transition-transform duration-500`}>
                {isDarkMode ? <FaSun className="text-yellow-500" /> : <FaMoon className="text-gray-500" />}
            </div>
        </button>
    );
};

export default ThemeSwitcher;
