import React from "react";
import "../index.css";

export default function PolicyPageEN() {
    return (
        <>
            <section className="relative md:py-24 py-16 bg-slate-900">
    <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
            <div className="lg:col-span-7">
                <div className="lg:ms-7">
                    <h2 className="text-white text-2xl mb-4">Privacy Policy</h2>

                    <h3 className="text-white text-xl mt-6 mb-2">1. Introduction</h3>
                    <p className="text-slate-300 mb-4">Live Spain Visa Consulting ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you visit our website https://livespainvisa.com/ (the "Site") and use our services.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">2. Information We Collect</h3>
                    <ul className="list-disc list-inside text-slate-300 mb-4">
                        <li><strong>Personal Information:</strong> When you fill out a form on our Site, we collect personal information such as your name, email address, and the content of your message.</li>
                        <li><strong>Payment Information:</strong> When you make a purchase through our Site, your payment information is collected and processed by our payment processor, Stripe. We do not store your payment information on our servers.</li>
                    </ul>

                    <h3 className="text-white text-xl mt-6 mb-2">3. How We Use Your Information</h3>
                    <ul className="list-disc list-inside text-slate-300 mb-4">
                        <li>To provide, maintain, and improve our services.</li>
                        <li>To communicate with you, respond to your inquiries, and provide customer support.</li>
                        <li>To process transactions and send you related information, including purchase confirmations and invoices.</li>
                        <li>To send you marketing communications, if you have opted in to receive them.</li>
                    </ul>

                    <h3 className="text-white text-xl mt-6 mb-2">4. Sharing Your Information</h3>
                    <ul className="list-disc list-inside text-slate-300 mb-4">
                        <li><strong>With Service Providers:</strong> We share your information with third-party service providers who perform services on our behalf, such as EmailJS for form submissions and Stripe for payment processing.</li>
                        <li><strong>For Legal Reasons:</strong> We may share your information to comply with legal obligations or respond to lawful requests by public authorities.</li>
                    </ul>

                    <h3 className="text-white text-xl mt-6 mb-2">5. Data Retention</h3>
                    <p className="text-slate-300 mb-4">We retain your personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">6. Your Rights</h3>
                    <p className="text-slate-300 mb-4">You have the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain types of data processing and to request data portability. To exercise these rights, please contact us at info@livespainvisa.com.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">7. Data Security</h3>
                    <p className="text-slate-300 mb-4">We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">8. International Data Transfers</h3>
                    <p className="text-slate-300 mb-4">If you are located outside the European Union, your information may be transferred to and processed in countries outside of your own. We ensure that appropriate safeguards are in place to protect your data.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">9. Changes to This Privacy Policy</h3>
                    <p className="text-slate-300 mb-4">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Site. You are advised to review this Privacy Policy periodically for any changes.</p>

                    <h3 className="text-white text-xl mt-6 mb-2">10. Contact Us</h3>
                    <p className="text-slate-300 mb-4">If you have any questions about this Privacy Policy, please contact us at:</p>
                    <p className="text-slate-300 mb-4">Live Spain Visa Consulting<br />info@livespainvisa.com<br />Calle Comte D’Urgell 100, piso 7-3 08011 Barcelona - España <br />Calle General Lacy 21, 1 Derecha 28045 Madrid - España</p>
                </div>
            </div>
        </div>
    </div>
</section>

        </>
    );
}
