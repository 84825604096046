import React, {useState} from "react";
import Header from "../sections/Header";
import HomeSection from "../sections/HomeSection";
import ServicesSection from "../sections/ServicesSection";
import NewsSection from "../sections/NewsSection";
import TeamSection from "../sections/TeamSection";
import ContactSection from "../sections/ContactSection";
import Footer from "../sections/Footer";
import PaymentSection from "../sections/PaymentSection";

export default function Index() {
    const [isDarkMode, setIsDarkMode] = useState(false);

    const toggleDarkMode = () => {
        const htmlElement = document.documentElement;

        if (htmlElement.classList.contains('dark')) {
            htmlElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
            setIsDarkMode(false);
        } else {
            htmlElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
            setIsDarkMode(true);
        }
    };

    return (
        <>
            <div>
                <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
                <HomeSection isDarkMode={isDarkMode}/>
                <ServicesSection/>
                <NewsSection/>
                <TeamSection/>
                <ContactSection/>
                <PaymentSection/>
                <Footer isDarkMode={isDarkMode} />
            </div>
        </>
    )
}
