import React from "react";
import Footer from "../sections/Footer";
import PolicyPage from "../sections/PolicyPageES";


export default function PolicyES() {

    return (
        <>
            <div>
                <PolicyPage/>
                <Footer/>
            </div>
        </>
    )
}
