import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import i18n, {t} from "i18next";
import { Trans } from 'react-i18next';
import "../index.css";

export const ContactUsForm = () => {
    const consent = <span><Trans
        i18nKey="consent_contact"
        components={{
            1: <a href={i18n.language === 'es' ? "/policyES/" : "/policyEN/"} className="text-blue-500 underline hover:text-blue-700 hover:underline" target="_blank"></a>
            }}
    /></span>
    const form = useRef();
    const initialFormData = { name: '', email: '', message: '' };
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const resetForm = () => {
        setFormData(initialFormData);
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ccl5lbj', 'template_ke3277h', form.current, 'eToc02nKGh_3EFG4p')
            .then((result) => {
                alert(i18n.t('email_sent'));
                resetForm();
            }, (error) => {
                alert(i18n.t('email_failed'));
            });
    };

    return (
            <div className="lg:col-span-8">
                <div className="p-6 rounded-md shadow bg-primary-light dark:bg-primary-dark">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="grid lg:grid-cols-12 lg:gap-6">
                            <div className="lg:col-span-6 mb-5">
                                <input type="text" name="name" value={formData.name} className="form-input border border-gray-300 rounded-md w-5/6 bg-primary-light dark:bg-primary-dark text-primary-light dark:text-primary-dark" onChange={handleChange} placeholder={t('contact_form_name')} required/>
                            </div>

                            <div className="lg:col-span-6 mb-5">
                                <input type="email" name="email" value={formData.email} className="form-input border border-gray-300 rounded-md w-5/6 bg-primary-light dark:bg-primary-dark text-primary-light dark:text-primary-dark" onChange={handleChange} placeholder={t('contact_form_email')} required/>
                            </div>
                        </div>

                        <div className="grid grid-cols-1">
                            <div className="mb-5">
                                <textarea name="message" value={formData.message} className="form-input border border-gray-300 rounded-md h-28 w-11/12 bg-primary-light dark:bg-primary-dark text-primary-light dark:text-primary-dark" onChange={handleChange} placeholder={t('contact_form_message')} required/>
                            </div>
                        </div>
                        <div className="flex items-center mb-4">
                            <input type="checkbox" id="checkbox-contact" name="consent-contact" className="form-checkbox h-5 w-5 text-blue-700 rounded focus:ring-2 focus:ring-blue-600" required></input>
                            <label for="checkbox-contact" className="ml-2 text-m font-medium text-primary-light dark:text-primary-dark">{consent}</label>
                        </div>
                        <button type="submit" name="send" className="btn bg-button-light dark:bg-button-dark text-buttonText-light dark:text-buttonText-dark hover:bg-blue-600 border-blue-900 hover:border-blue-900 rounded-md h-11 justify-center flex items-center w-2/5">{t('contact_form_send')}</button>
                    </form>
                </div>
            </div>
    );
};
